import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule, HEADER_CONFIG } from './app/app.module';
import {
  ENVIRONMENT_CONFIG,
  EnvironmentConfiguration,
} from './environments/models/environment.model';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

void fetch('./assets/environment-config.json')
  .then((resp) => resp.json())
  .then((config: EnvironmentConfiguration) => {
    platformBrowserDynamic([
      { provide: ENVIRONMENT_CONFIG, useValue: config },
      {
        provide: HEADER_CONFIG,
        useValue: {
          redirectUri: config.oAuth.redirectURL,
          postLogoutRedirectUri: config.oAuth.postLogoutRedirectURL,
          clientId: config.oAuth.clientId,
          authority: config.oAuth.authority,
          authorityDomain: config.oAuth.authorityDomain,
          portalURL: config.portal.redirectURL,
          authConfigUrls: new Map([["https://api.apps.tst.gea.com/*", null]]),
        },
      },
    ])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
