import { Component } from '@angular/core';

@Component({
  selector: 'app-click-wrap',
  templateUrl: './click-wrap.component.html',
  styleUrl: './click-wrap.component.scss'
})
export class ClickWrapComponent {
  eulaAccepted = false;
  dpnAccepted = false;

  constructor() {}

  ngOnInit() {
  }

  onAccept() {
    document.cookie = 'consent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT';
    window.location.href = 'dashboard';
  }

  onCancel() {
    // browser back
    window.history.back();
  }
}
