import { Component } from '@angular/core';
import { Notification } from '../models/notification';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrl: './notification-list.component.scss'
})
export class NotificationListComponent {
  notifications: Notification[] = [];

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    this.notificationService.getNotifications().subscribe((notifications: Notification[]) => {
      this.notifications = notifications;
    });
  }
}
