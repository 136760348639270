<div class="header">
    About - InsightPartner
    <div style="padding-top: 3px; font-size: 18px;" [routerLink]="['/dashboard']">
        X
    </div>
</div>
<div class="container">
    <mat-accordion>
        <mat-expansion-panel style="margin-bottom: 0px; box-shadow: none; border-radius: 0px;">
            <mat-expansion-panel-header>
                <mat-panel-title> Imprint </mat-panel-title>
            </mat-expansion-panel-header>
            <a style="text-decoration: none; color: black;" href="assets/documents/Imprint InsightPartner_Dairy_Powder 2024-11-01.pdf">
            <div class="panel-body">
                <img src="assets/icons/PDF_file_icon.svg" style="margin-right: 20px; height: 20px;">
                Imprint InsightPartner_Dairy_Powder 2024-11-01.pdf
                </div>
            </a>
        </mat-expansion-panel>

        <mat-expansion-panel style="margin-bottom: 0px; box-shadow: none; border-radius: 0px;">
            <mat-expansion-panel-header>
                <mat-panel-title> Data Protection Notice </mat-panel-title>
            </mat-expansion-panel-header>
            <a style="text-decoration: none; color: black;" href="assets/documents/DataProtectionNotice InsightPartner_Dairy_Powder 2023-02-01.pdf">
            <div class="panel-body">
                <img src="assets/icons/PDF_file_icon.svg" style="margin-right: 20px; height: 20px;">
                DataProtectionNotice InsightPartner_Dairy_Powder 2023-02-01.pdf
                </div>
            </a>
        </mat-expansion-panel>
        
        <mat-expansion-panel style="margin-bottom: 0px; box-shadow: none; border-radius: 0px;">
            <mat-expansion-panel-header>
                <mat-panel-title> End User License Agreement </mat-panel-title>
            </mat-expansion-panel-header>
            <a style="text-decoration: none; color: black;" href="assets/documents/EULA InsightPartner_Dairy_Powder 2023-10-01.pdf">
            <div class="panel-body">
                <img src="assets/icons/PDF_file_icon.svg" style="margin-right: 20px; height: 20px;">
                EULA InsightPartner_Dairy_Powder 2023-10-01.pdf
                </div>
            </a>
        </mat-expansion-panel>
    </mat-accordion>
</div>
