<div class="container">
    <div class="inner-container">
        <div style="display: flex; flex-direction: row; margin-bottom: 16px;">
            <mat-checkbox [(ngModel)]="eulaAccepted" color="primary" style="margin-right: 5px;"></mat-checkbox>
            <div>
                I agree to the InsightPartner 
                <a style="text-decoration: none; color: #0303B8;" href="assets/documents/EULA InsightPartner_Dairy_Powder 2023-10-01.pdf">
                    End User License Agreement
                </a>
            </div>
        </div>
        <div style="display: flex; flex-direction: row;">
            <mat-checkbox [(ngModel)]="dpnAccepted" color="primary" style="margin-right: 5px;"></mat-checkbox>
            <div>
                I have taken note of the InsightPartner  
                <a style="text-decoration: none; color: #0303B8;" href="assets/documents/DataProtectionNotice InsightPartner_Dairy_Powder 2023-02-01.pdf">
                    Data Protection Notice
                </a>
            </div>
        </div>
        <div style="margin-top: 32px;">
            <button mat-stroked-button (click)="onCancel()" style="margin-right:16px">Cancel</button>
            <button mat-flat-button color="primary" [disabled]="!eulaAccepted || !dpnAccepted" (click)="onAccept()">Continue</button>
        </div>
    </div>
</div>