<div class="spinner" *ngIf="loading">
    <p-progressSpinner animationDuration="2s" strokeWidth="4">
    </p-progressSpinner>
</div>
<div *ngIf="!loading">
    <div *ngIf="notification">
        <div class="header" style="display: flex;">
            {{notification.machineName}}

            <div style="margin-left: auto;" (click)="goBack()">
                <img src="assets/icons/24px_close.svg" style="margin-right: 10px;">
            </div>
        </div>
        <div class="top">
            <div style="display: flex;">
                <div style="display: flex;" class="warning-chip">
                    <div>
                        <img src="assets/icons/16px_warning-sign.svg" style="margin-right: 10px;">
                    </div>
                    Attention
                </div>
                <div style="margin-left: 10px; margin-top: 10px; padding: 8px;">
                    {{ notification.timestamp | timeAgo }}
                </div>
            </div>

            <div style="display: flex;">
                <div class="notification-title">
                    {{ notification.title }}
                </div>
            </div>
        </div>
        <div class="panel-container">
            <mat-accordion>
                <mat-expansion-panel expanded="true" style="margin-bottom: 0px; box-shadow: none; border-radius: 0px;">
                    <mat-expansion-panel-header>
                        <mat-panel-title> Impact </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="panel-body">
                        <div class="card-header">
                            {{ notification.impactTitle }}
                        </div>
                        <div class="impact-body">
                            {{notification.impactText}}
                        </div>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel style="margin-bottom: 0px; box-shadow: none; border-radius: 0px;">
                    <mat-expansion-panel-header>
                        <mat-panel-title> Diagnosis / Recommendations </mat-panel-title>
                    </mat-expansion-panel-header>
                    
                    <div class="panel-body" *ngFor="let failureModeCause of notification.failureModeCauses">
                        <div class="card-header">
                            {{failureModeCause.name}}
                        </div>
                        <div class="failureModeCause-body" [innerHTML]="failureModeCause.solutionText | newlineToList">
                        </div>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div *ngIf="!notification">
        <div class="header" style="display: flex;">
            <div style="margin-left: auto;" (click)="goBack()">
                <img src="assets/icons/24px_close.svg" style="margin-right: 10px;">
            </div>
        </div>
        <div>
            <div style="text-align: center; margin-top: 20px;font-weight: 600;">
                Notification no longer valid
            </div>
        </div>
    </div>
</div>