import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { Notification } from '../models/notification';
import { Location } from '@angular/common';

@Component({
  selector: 'app-machine',
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent {
  loading = false;
  notification?: Notification;

  constructor(
    private notificationService: NotificationService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute) { 
    
    this.route.paramMap.subscribe(params => {
      const notificationId = params.get('id');

      if (!notificationId) {
        return;
      }

      this.loading = true;
      this.notificationService.getNotification(notificationId).subscribe({
        next:(notification: Notification) => {
          this.notification = notification;
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
        }
      });
    });
  }

  goBack() {
    if (window.history.length > 1) {
      this.location.back();
    }
    else {
      // go to /dashboard/notifications
      this.router.navigate(['/dashboard/notifications']);
    }
  }
}