import { Component } from '@angular/core';
import { Notification } from '../models/notification';
import { NotificationService } from '../services/notification.service';
import { MachineService } from '../services/machine.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrl: './notification-list.component.scss'
})
export class NotificationListComponent {
  notifications: Notification[] = [];

  constructor(
    private notificationService: NotificationService, 
    private machineService: MachineService,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const machineIdParm = params.get('machineId');

      // parse machineId to number
      // if machineId is not defined, get all notifications
      var machineId = machineIdParm ? parseInt(machineIdParm) : null;

      if (machineId) {
        this.machineService.getMachineNotifications(machineId).subscribe((notifications: Notification[]) => {
          this.notifications = notifications;
        });
        return;
      }

      this.notificationService.getNotifications().subscribe((notifications: Notification[]) => {
        this.notifications = notifications;
      });
    });
  }
}
