import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'newlineToList'
})
export class NewlineToListPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return value;
        return '<ul>' + value.split('\n').map(line => `<li>${line}</li>`).join('') + '</ul>';
    }
}
