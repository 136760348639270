import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ConsentGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const consentCookie = document.cookie.split('; ').find(row => row.startsWith('consent='));
    if (consentCookie) {
      return true;
    } else {
      this.router.navigate(['/clickwrap']);
      return false;
    }
  }
}
