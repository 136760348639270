import { Component } from '@angular/core';
import { ColumnDefinition, ColumnRendererComponent, IconRendererComponent } from '@gea/digital-ui-lib';
import { MachineService } from '../services/machine.service';
import { Machine } from '../models/machine';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  loading = false;
  errorString = '';
  plantName = '';
  machines: Machine[] = [];
  displayedColumns: string[] = ['name', 'status'];

  columnDefinitions: ColumnDefinition[] = [
    {
      displayName: 'Status',
      key: 'status',
      renderer: {
        component: IconRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          mapping: {
            0: '16px_check',
            1: '16px_warning-sign',
          },
        },
      },
    },
    {
      displayName: 'Name',
      key: 'name',
    },
  ];
  
  constructor(
    private machineService: MachineService) {}

  ngOnInit() {
    this.loading = true;
    this.machineService.getMachines().subscribe({
      next: (machines: Machine[]) => {
        this.machines = machines;
        this.plantName = machines[0] ? machines[0].plantName : '';
        this.loading = false;
      },
      error: (error) => {
        this.errorString = error.message
        this.loading = false;
      }
    });
  }
}