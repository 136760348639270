import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../../environments/models/environment.model';
import { Machine } from '../models/machine';
import { Notification } from '../models/notification';

@Injectable({
  providedIn: 'root'
})
export class MachineService {
  baseUrl = "";
  constructor(
    private httpClient: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) public environment: EnvironmentConfiguration
  ) {
    this.baseUrl = this.environment.serviceCall.baseURL!;
    this.baseUrl = this.baseUrl.slice(-1) === '/' ? this.baseUrl : this.baseUrl + '/';
    this.baseUrl = `${this.baseUrl}machine`;
  }

  /*machines = [
    {
      id: 0,
      status: 0,
      name: 'Vacuum Pump K1G01',
    },
    {
      id: 1,
      status: 0,
      name: 'Vacuum Pump K1G02',
    },
    {
      id: 2,
      status: 0,
      name: 'Concentrate Extraction Pump E1G05',
    },
    {
      id: 3,
      status: 1,
      name: 'CIP Feed Pump B1G02'
    },
    {
      id: 4,
      status: 0,
      name: 'Condenser D1E01'
    },
  ];*/

  getMachines() {
    return this.httpClient.get<Machine[]>(this.baseUrl);
  }

  getMachineNotifications(machineId: number){
    return this.httpClient.get<Notification[]>(`${this.baseUrl}/notifications/${machineId}`);
  }

  /*getMachine(id: number) {
    return this.httpClient.get<Machine>(`${this.baseUrl}/${id}`);
  }*/
}
