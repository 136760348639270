<div *ngIf="errorString">
    {{errorString}}
</div>
<div class="spinner" *ngIf="loading">
      <p-progressSpinner animationDuration="2s" strokeWidth="4">
      </p-progressSpinner>
    </div>
<div class="container">
    <div class="header" style="display: flex;">
        Evaporator
    </div>
    <div>
        <a style="color: inherit; text-decoration: none;" [routerLink]="['/notifications']">
            <div class="notifications-container" >
                <div style="display: flex; flex-direction: row;">
                    <img src="assets/icons/16px_bulb-63.svg" style="margin-right: 10px;">
                    <div style="font-weight: 500; font-size: 16px;color: #0303B8;">Notifications</div>
                    <img src="assets/icons/16px_chevron-right.svg" style="margin-left: auto;">
                </div>
                <div style="font-weight: 400; margin-top: 20px">See GEA recommendations</div>
            </div>
        </a>
    </div>
    <div class="machine-overview">
        <div class="sub-heading">{{plantName}}</div>
        <table mat-table [dataSource]="machines">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Machinery </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef style="text-align: end;"> Status </th>
                <td mat-cell *matCellDef="let element" class="align-right">
                    <mat-icon *ngIf="element.status === 0" class="status-icon" style="color: green;">circle</mat-icon>
                    <mat-icon *ngIf="element.status > 0" class="status-icon" style="color: red;">circle</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/notifications', row.id]"></tr>
        </table>
    </div>

    <div class="footer">
        <button mat-button class="footer-button" [routerLink]="['/about']">App About</button>
    </div>
</div>