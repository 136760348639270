import { Component, DestroyRef } from '@angular/core';
import { ColumnDefinition, ColumnRendererComponent, IconRendererComponent, UserState } from '@gea/digital-ui-lib';
import { MachineService } from '../services/machine.service';
import { Machine } from '../models/machine';
import { NotificationService } from '../services/notification.service';
import { Notification } from '../models/notification';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Store } from '@ngxs/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  loading = false;
  errorString = '';
  activeSection = 'notifications';
  notifications: Notification[] = [];
  machines: Machine[] = [];
  displayedColumns: string[] = ['name', 'status'];

  columnDefinitions: ColumnDefinition[] = [
    {
      displayName: 'Status',
      key: 'status',
      renderer: {
        component: IconRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          mapping: {
            0: '16px_check',
            1: '16px_warning-sign',
          },
        },
      },
    },
    {
      displayName: 'Name',
      key: 'name',
    },
  ];
  
  constructor(
    private machineService: MachineService,
    private notificationService: NotificationService,
    private store: Store,
    private destroyed: DestroyRef,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const activeSectionParm = params.get('activeSection');
      this.activeSection = activeSectionParm || 'notifications';
    });

    this.loading = true;

    forkJoin({
      notifications: this.notificationService.getNotifications(),
      machines: this.machineService.getMachines(),
    }).subscribe({
      next: ({ notifications, machines }) => {
        // set notifications and sort by date
        this.notifications = notifications;
        this.notifications = this.notifications.sort((a, b) => {
          return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
        });

        // set machines and sort by name
        this.machines = machines;
        this.machines = this.machines.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        this.loading = false;
      },
      error: (error) => {
        // if 401 error
        if (error.status === 401) {

          // try to get token value from store and set it as a cookie
          this.store
          .select(UserState.token)
          .pipe(takeUntilDestroyed(this.destroyed))
          .subscribe((token) => {
            if (token) {
              // create a cookie named Authorization with token value
              document.cookie = `Authorization=${token}`;
            }
          });
        }

        this.errorString = error.message;
        this.loading = false;
      }
    });
  }

  showSection(section: string) {
    this.activeSection = section;
  }
}