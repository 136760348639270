import { Component } from '@angular/core';
import { MachineService } from '../services/machine.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { Notification } from '../models/notification';

@Component({
  selector: 'app-machine',
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent {
  notification?: Notification;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute) { 
    
    this.route.paramMap.subscribe(params => {
      const notificationId = params.get('id');

      if (!notificationId) {
        return;
      }

      this.notificationService.getNotification(notificationId).subscribe((notification: Notification) => {
        this.notification = notification;
      });
    });
  }
}