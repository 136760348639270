<div class="container">
    <a style="color: inherit; text-decoration: none;" [routerLink]="['/dashboard']">
        <div class="header" style="display: flex;">
            <div>
                <img src="assets/icons/24px_chevron-left.svg" style="margin-right: 10px;">
            </div>
            Evaporator – Notifications
        </div>
    </a>
    <div class="list-container">
        <div *ngFor="let notification of notifications" class="list-item" [routerLink]="['/notification', notification.id]">
            <div class="notification-machine-name">{{ notification.machineName }}</div>
            <div style="display: flex;">
                <div style="display: flex;" class="warning-chip">
                        <img src="assets/icons/16px_warning-sign.svg" style="margin-right: 10px;">
                    <div style="color: #692C01;">
                        Attention
                    </div>
                    <div style="font-weight: 400; margin-left: 16px">
                        {{ notification.timestamp | timeAgo }}
                    </div>
                </div>
            </div>
            <div class="notification-title">
                {{ notification.title}}
            </div>
        </div>
    </div>
</div>